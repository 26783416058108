import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-informationtechnology',
  templateUrl: './informationtechnology.component.html',
  styleUrls: ['./informationtechnology.component.css']
})
export class InformationtechnologyComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
  }

  ngOnInit() {
    this.loadScripts();
    this.title.setTitle('Careers');
  }

  loadScripts() {
    const dynamicScripts = [
      '../assets/js/anim.js',
      '../assets/js/script.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }

}
